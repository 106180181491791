import React from "react";
import { IoMdMail } from "react-icons/io";
import { HiPlusSm } from "react-icons/hi";
import classNames from "classnames";

// components
import MailIcon from "@/components/icons/MailIcon";
import Button from "@/shared/Button";
import Link from "@/components/Link";

// helpers
import { NAV_ITEMS } from "./helpers";

// interfaces
import { AppFooterInterface } from "./IAppFooter";

// assets
import BookDemoIcon from "@/components/icons/BookDemoIcon";
import styles from "@/assets/styles/components/Footer/app-footer.module.scss";
import { useDimension } from "@/hooks/useDimensions";


const AppFooter = ({ isLandlord }: AppFooterInterface) => {
  const currentYear = new Date().getFullYear();
  const footerClassName = classNames(
    [styles["locl-app-footer"]],
    { [styles["locl-app-footer-ll"]]: isLandlord }
  );

  const [width] = useDimension();
  const isTablet = width < 1200;
  const isMobile = width < 867;

  const renderButton = () => {

    if (isLandlord) {

      return (
        <Button
          to="/locations"
          className={styles["locl-app-footer__button"]}
          icon={<HiPlusSm className={styles["locl-app-footer__button-icon"]}/>}
        >
          List your space
        </Button>
      );
    }

    return (
      <Button
        to="/book-a-demo"
        type="secondary"
        className={styles["locl-app-footer__button"]}
        icon={<BookDemoIcon className={styles["locl-app-footer__button-icon"]} />}
        success
        ghost
      >
        Book a demo
      </Button>
    );
  };

  const renderSupportLink = () => (
    <Link href={"/support"} className={styles["locl-app-footer__support"]}>
      Support
    </Link>
  )

  return (
    <div className={footerClassName}>
      <div className={styles["locl-app-footer__inner"]}>
        <div className={styles["locl-app-footer__left"]}>
          <div className={styles["locl-app-footer__copyright"]}>
            © {currentYear} Location Live Limited
          </div>
          <ul className={styles["locl-app-footer__nav"]}>
            {
              NAV_ITEMS.map(item => (
                <li className={styles["locl-app-footer__nav-item"]} key={item.href}>
                  <Link href={item.href} className={styles["locl-app-footer__nav-link"]}>
                    {item.label}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
        {!isTablet && !isMobile && renderSupportLink() }
        <div className={styles["locl-app-footer__right"]}>
          <div className={styles["locl-app-footer__right-info"]}>
            <a href="mailto:hello@locationlive.com" className={styles["locl-app-footer__email"]}>
              {
                isLandlord
                  ? <IoMdMail className={styles["locl-app-footer__email-icon-ll"]} />
                  : <MailIcon />
              }
              <span className={styles["locl-app-footer__email-text"]}>hello@locationlive.com</span>
            </a>
            { isMobile && renderSupportLink() }
          </div>
          {isTablet && !isMobile && renderSupportLink() }
          {renderButton()}
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
