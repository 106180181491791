"use client";

import { ReactNode, useEffect } from "react";
import { deleteCookie } from "cookies-next";
import { notFound } from "next/navigation";

// components
import AuthHeader from "@/components/Header/AuthHeader";
import AppFooter from "@/components/Footer/AppFooter";

// contexts
import { LockoutModalContextProvider } from "@/contexts/LockoutModalContext";

// assets
import styles from "@/assets/styles/layouts/auth-layout.module.scss";


const AuthLayout = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
  }, []);

  if (process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD) {
    notFound();

    return null;
  }

  return (
    <div className={styles["locl-auth-layout"]} data-testid="locl-auth-layout">
      <LockoutModalContextProvider>
        <AuthHeader/>
        <div className={styles["locl-auth-layout__body"]}>
          { children }
        </div>
        <AppFooter />
      </LockoutModalContextProvider>
    </div>
  );
};

export default AuthLayout;
