import React from "react";

// interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";

const MailIcon = (props: IIconComponentProps<HTMLSpanElement>) => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.8V6.8C3 7.4 3.3 7.9 3.8 8.3L9.8 12.4C11.2 13.3 12.9 13.3 14.3 12.4L20.3 8.4C20.7 7.9 21 7.4 21 6.8V6.8C21 5.8 20.2 5 19.2 5H4.8C3.8 5 3 5.8 3 6.8Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7V17C3 18.1 3.9 19 5 19H19C20.1 19 21 18.1 21 17V7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58789 18.4104L9.68189 12.3164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3599 12.3594L20.4119 18.4114"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
export default MailIcon;
