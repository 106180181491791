export const NAV_ITEMS = [
  {
    label: "Terms of Use",
    href: "/terms-of-use",
  },
  {
    label: "Booking Terms",
    href: "/bookingterms",
  },
  {
    label: "Privacy Policy",
    href: "/privacy",
  },
  {
    label: "Cookie Notice",
    href: "/cookies",
  },
];
